
import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import axiosInstance from '../axios'
// import { AuthContext } from '../shared/context/auth-context';
// import { Routes } from "../routes";
import BgImage from "../assets/img/illustrations/signin.svg";

export default () => {
  const history = useHistory();
  const [validated, setValidated] = useState(false);
  // const auth = useContexts(AuthContext);

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);

    // try {
    //   const responseData = await axiosInstance
    //   .post("api/users/login", formData)
    //   .then(async (response) => {
    //     const loginDataJSON = JSON.stringify(response.data);

    //     await localStorage.setItem('userData', loginDataJSON);

    //     history.push('/')
    //   })
    //   .catch((err) => {
    //     console.log('Login API error!')
    //   })
      
    // } catch (error) {
    //   console.log('Something went wrong!')
    // }
    try {
      await axiosInstance
        .post("api/users/login", formData)
        .then(async (response) => {
          const loginDataJSON = JSON.stringify(response.data);
          
          // Set userData in localStorage
          await localStorage.setItem('userData', loginDataJSON);
    
          // Access userData only after it has been successfully set
          const userData = JSON.parse(loginDataJSON);
    
          // Update axiosInstance headers with the new token
          axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${userData.token}`;
          
          history.push('/');
        })
        .catch((err) => {
          console.log('Login API error!')
        });
    } catch (error) {
      console.log('Something went wrong!')
    }
  };

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Admin Login!</h3>
                </div>

                {/* Form Start */}
                <Form className="mt-4"
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmit}
                >
                  {/* Email */}
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        required
                        type="email"
                        placeholder="example@company.com"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </InputGroup>
                  </Form.Group>

                  {/* Password */}
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Your Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type="password"
                          placeholder="Password"
                          name="password"
                          value={formData.password}
                          onChange={handleChange}
                        />
                      </InputGroup>
                    </Form.Group>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      {/* <Form.Check type="checkbox">
                        <FormCheck.Input id="defaultCheck5" className="me-2" />
                        <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Remember me</FormCheck.Label>
                      </Form.Check> */}
                      {/* <Card.Link className="small text-end">Lost password?</Card.Link> */}
                    </div>
                  </Form.Group>

                  {/* Action Button */}
                  <Button variant="primary" type="submit" className="w-100">
                    Log in
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

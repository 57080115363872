
import React from "react";

export default () => {
  return (
    <>
      <h1>Welcome Admin!</h1>
    </>
  );
};
